<template>
	<div id="Editorial-AwardTypeList" class="main-wrap Editorial-wrap">
		<div class="flex between w100">
			<div class="left-box"><MainMenu :title="step==1?'编委初评':'终评'" :list="[{title:L(step==1?'初评入口':'终评入口'),path:'/Editorial/AwardTypeList/'+step},{title:L('评选说明'),path:'/Editorial/Info/'+step}]"/></div>
			
			<div class="w850" style="margin:unset">
				<template v-if="step==1">
					<div class="title-h2">INITIAL ASSESSMENT ENTRANCE</div>
					<div class="title-h1">{{L("初评入口")}}</div>
					<div class="tips">
						{{L("请您评选以下")}} {{awardTypeList.length}} {{L("个类别的作品")}}
					</div>
					<div class="mt-20"></div>
					<div class="awardType-list flex between middle wrap">
						<div v-for="item in awardTypeList" :key="item.code" class="item">
							<div class="box" @click="doSelectAwardType(item)">
								<div class="title pmiddle">{{L(item.name)}}</div>
							</div>
							<div class="status">
								<div class="submited" v-if="resultMap[item.id] && resultMap[item.id].isSubmit">
									{{L("已提交评选结果")}}
								</div>
								<div class="" v-else>
									{{L("未提交评选结果")}}
								</div>
							</div>
							
						</div>
						<div class="item empty" v-for="(item,index) in 10" :key="index"></div>
					</div>
				</template>
				<template v-if="step==2">
					<div class="title-h2">FINAL ASSESSMENT ENTRANCE</div>
					<div class="title-h1">{{L("终评入口")}}</div>
					<div class="awardType-list flex between middle wrap">
						<div class="item">
							<div class="box" @click="doSelectAwardType(false)">
								<div class="title pmiddle" v-html="L('年度主题单元')"></div>
							</div>
							<div class="status">
								<div class="submited" v-if="resultMap.isSubmit">
									{{L("已提交评选结果")}}
								</div>
								<div class="" v-else>
									{{L("未提交评选结果")}}
								</div>
							</div>
							
						</div>
					</div>
				</template>
				
			
			</div>
		</div>	
	</div>
</template>
<script>
import MainMenu from '/src/views/MainMenu';
export default {
  name: 'Editorial-AwardTypeList',
  components: {
    MainMenu
  },
  data(){
    return {
			awardTypeList:[],
			step:this.$route.params.step || 0,
			resultMap:{}
    };
  },
	watch:{
		$route(){
			this.step = this.$route.params.step || 0;
			this.$root.checkEditorialStage(this.step);
			this.initData();
		}
	},
  async mounted () {
		// console.log(this.$root.userData.config.groupType);
		// console.log(JSON.stringify(this.$root.awardTypeList));
		this.$root.checkEditorialStage(this.step);
		await this.initData();
  },
  methods: {
		async initData(){
			let userData = await this.$root.initUserData();
			
			if(userData.config.groupType == 'A' || userData.config.groupType == 'B' || userData.config.groupType == 'C'){
				this.awardTypeList = this.$root.awardTypeList.filter(item=>this.$root.CONFIG['groupType'+userData.config.groupType].includes(item.id));
				// this.awardTypeList = this.$root.awardTypeList.filter(item=>item.groupType == userData.config.groupType);
			} else {
				this.awardTypeList = this.$root.awardTypeList;
			}
			// if(userData.config.recommondAwardList && userData.config.recommondAwardList.length){
			// 	this.awardTypeList = userData.config.recommondAwardList.map(rid => {
			// 		return this.$root.awardTypeList.find(a => a.id == rid)
			// 	});//this.$root.awardTypeList.filter(item=>item.groupType == userData.config.groupType);
			// }else {
			// 	this.awardTypeList = []
			// 	// if(userData.config.groupType == 'A' || userData.config.groupType == 'B'){
			// 	// 	this.awardTypeList = this.$root.awardTypeList.filter(item=>item.groupType == userData.config.groupType);
			// 	// } else {
			// 	// 	this.awardTypeList = this.$root.awardTypeList;
			// 	// }
			// }
			
			
			
			let resultMap = {};
			if(userData && userData.config){
				if(this.step==1 && userData.config.firstScoreResult){
					userData.config.firstScoreResult.forEach(item=>{
						resultMap[item.aid] = {recommentList:item.recommentList||[],scoreList:item.scoreList||[],isSubmit:!!item.isSubmit};
					})
				}
				if(this.step==2 && userData.config.secondScoreResult){
					let result = userData.config.secondScoreResult.find(item=>item.aid==0);
					resultMap = {
						isSubmit:!!(result&&result.isSubmit),
						recommentList:(result&&result.recommentList)||[]
					}
				}
				
			}
			this.$set(this,'resultMap',resultMap);
		},
		doSelectAwardType(item){
			this.$root.toPage('/Editorial/AwardList/'+this.step+(item?('/'+item.id):''));
		}
  }
}
</script>

<style scoped lang="less">
.tips{
	font-size:14px;
}
.left-box{
	// position: absolute;
	flex-shrink: 0;
	// left:45px;
	width:150px;
}
.awardType-list{
	.item{
		width:calc((100% - 80px) / 3);
		
		min-width: 150px;
		margin-bottom:40px;
		.box{
			background-color: #EDEDED;
			text-align: center;
			font-size:20px;
			width: 100%;
			padding: calc(100% / 10 * 3) 0;
			cursor: pointer;
			position: relative;
			.title{
				left:0;
				right:0;
			}
		}
		.status{
			font-size:16px;
			line-height:2;
			.submited{
				color:#46E055;
			}
		}
		&.empty {
			height: 0px;
			margin-bottom:0;
			width:calc((100% - 80px) / 3);
			background-color: transparent;
		}	
	}
	
}
</style>